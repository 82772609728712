import { useState } from "react";
import {
  AlignItems,
  Box,
  ColorPreset,
  FontWeight,
  Glyph,
  HoverEffect,
  Icon,
  P,
  PlainButton,
  Space,
  TypeScale,
  Visibility,
} from "@gocardless/flux-react";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export interface MobilePreviewProps {
  rightPanel: React.ReactNode;
  isEmailPreview?: boolean;
  isFormPreview?: boolean;
  header?: string;
}

export const MobilePreview: React.FC<MobilePreviewProps> = ({
  rightPanel,
  header,
  isEmailPreview,
  isFormPreview,
}) => {
  const [showPreview, setShowPreview] = useState(false);
  const { i18n } = useLingui();
  const screenWidth = screen.width;

  return (
    <Visibility visible={["block", null, null, "none", null]}>
      <Box
        data-testid="mobile-preview"
        spaceAbove={2}
        borderRadius={1}
        borderWidth={1}
        borderColor={ColorPreset.BorderOnLight_05}
        gutterV={2}
        gutterH={1.5}
      >
        <PlainButton
          effect={HoverEffect.TextDecoration}
          onClick={() => setShowPreview(!showPreview)}
        >
          <Box layout="inline-flex" alignItems={AlignItems.Center}>
            <P
              weight={FontWeight.Bold}
              size={TypeScale.Size_03}
              color={ColorPreset.TextOnLight_01}
            >
              {header
                ? header
                : isEmailPreview || isFormPreview
                  ? `${i18n._(
                      t({
                        id: "request-payment.what-customer-sees",
                        message: "What your customer sees",
                      })
                    )}`
                  : `${i18n._(
                      t({
                        id: "request-payment.our-top-tip",
                        message: "Our top tip",
                      })
                    )}`}
            </P>
            <Space layout="inline" h={1} />
            <Icon name={!showPreview ? Glyph.ChevronDown : Glyph.ChevronUp} />
          </Box>
        </PlainButton>
        {showPreview && (
          <Box
            spaceAbove={1}
            maxWidth={[
              `${screenWidth - 82}px`,
              "550px",
              "550px",
              "500px",
              "550px",
            ]}
          >
            {rightPanel}
          </Box>
        )}
      </Box>
    </Visibility>
  );
};
